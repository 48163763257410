import { Box, Grid, useTheme } from "@mui/material";
import PlusIcon from '../assets/images/icons/plus.svg';
import MinusIcon from '../assets/images/icons/minus.svg';
import { useAssetUrlCreator } from "../helpers/assetUrlCreator";

const NumericUpDown = ({
  value = 0,
  handleChange = (v) => { },
  min = 0,
  max = 99,
  unitText = '',
}) => {

  const { createAssetUrl } = useAssetUrlCreator();
  const theme = useTheme();

  const buttonStyle = {
    flexShrink: 0,
    width: '30px',
    height: '30px',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
  const disabledButtonStyle = {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.background.fifth,
  }

  const minusButtonWrapperStyle = {
    '& > button': {
      ...buttonStyle,
      borderRadius: '2px 0 0 2px',
      backgroundImage: `url(${createAssetUrl(MinusIcon)})`,

      '&:disabled': {
        ...disabledButtonStyle,
      }
    },
  }

  const plusButtonWrapperStyle = {
    '& > button': {
      ...buttonStyle,
      borderRadius: '0px 2px 2px 0px',
      backgroundImage: `url(${createAssetUrl(PlusIcon)})`,

      '&:disabled': {
        ...disabledButtonStyle,
      }
    }
  }

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      width='140px'
    >
      <Box sx={minusButtonWrapperStyle}>
        <button onClick={() => handleChange(value - 1)} disabled={value <= min} />
      </Box>
      <span>{value}{unitText}</span>
      <Box sx={plusButtonWrapperStyle}>
        <button onClick={() => handleChange(value + 1)} disabled={value >= max} />
      </Box>
    </Grid>
  );
}

export default NumericUpDown;