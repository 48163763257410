import { Switch as MuiSwitch } from "@mui/material";
import styled from "@emotion/styled";
import { useMemo } from "react";

const Switch = ({
  checked,
  handleChange,
}) => {

  const StyledSwitch = useMemo(() => styled(MuiSwitch)(({ theme }) => ({
    width: '40px',
    height: '20px',
    padding: 0,
    display: 'flex',
    border: '1px solid #333',
    borderRadius: 16 / 2,
    '&:active': {
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(20px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#333',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#fff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      color: '#333',
      transition: theme.transitions.create(['width'], {
        duration: 500,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fff',
      boxSizing: 'border-box',
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })), []);

  return (
    <StyledSwitch checked={checked} onChange={(e) => handleChange(e.target.checked)} />
  );
}

export default Switch;