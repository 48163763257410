import styled from "@emotion/styled";
import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import NumericUpDown from "./NumericUpDown";

const RoomAndPersonCounter = ({
  value = {},
  handleChange = () => { },
  maxRoomCount = 10,
  maxPersonCount = 10,
}) => {

  const { t } = useTranslation();
  const Label = styled(Typography)(()=>({
    fontSize: '14px',
    fontWeight: 500,
  }));
  const InnerLabel = styled(Label)(()=>({
    width: '80px',
    paddingLeft: '15px',
  }));
  const Border = styled(Divider)(({theme})=>({
    borderColor: theme.palette.primary.border,
  }));

  return (
    <Grid container direction='column' rowGap={2.5}>
      {/* 室数 */}
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Label variant="body1">{t('number_room')}</Label>
        <NumericUpDown
          value={value.roomCount}
          handleChange={(v) => handleChange({...value, roomCount: v})}
          min={1}
          max={maxRoomCount}
          unitText={t('room')}
        />
      </Grid>
      <Border />
      {/* 大人 */}
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Label variant="body1">{t('adult')}</Label>
        <NumericUpDown
          value={value.adultCount}
          handleChange={(v) => handleChange({...value, adultCount: v})}
          min={1}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Border />
      {/* 子ども(小学生) */}
      <Label variant="body1">{t('children')}</Label>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel>{t('member_condition.elementary')}</InnerLabel>
        <NumericUpDown
          value={value.upperElementaryCount}
          handleChange={(v) => handleChange({...value, upperElementaryCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel>{t('member_condition.student')}</InnerLabel>
        <NumericUpDown
          value={value.lowerElementaryCount}
          handleChange={(v) => handleChange({...value, lowerElementaryCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Border />
      {/* 幼児 */}
      <Label variant="body1">{t('child_infant')}</Label>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel><Trans i18nKey='member_condition.meals_futon' /></InnerLabel>
        <NumericUpDown
          value={value.toddlerWithMealsAndBeddingCount}
          handleChange={(v) => handleChange({...value, toddlerWithMealsAndBeddingCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel>{t('member_condition.meal_only')}</InnerLabel>
        <NumericUpDown
          value={value.toddlerWithMealsOnlyCount}
          handleChange={(v) => handleChange({...value, toddlerWithMealsOnlyCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel>{t('member_condition.futon_only')}</InnerLabel>
        <NumericUpDown
          value={value.toddlerWithBeddingOnlyCount}
          handleChange={(v) => handleChange({...value, toddlerWithBeddingOnlyCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <InnerLabel><Trans i18nKey='member_condition.no_meals_no_futon' /></InnerLabel>
        <NumericUpDown
          value={value.toddlerWithoutMealsAndBeddingCount}
          handleChange={(v) => handleChange({...value, toddlerWithoutMealsAndBeddingCount: v})}
          min={0}
          max={maxPersonCount}
          unitText={t('person')}
        />
      </Grid>

    </Grid>
  );
}

export default RoomAndPersonCounter;