import { ScopedCssBaseline, ThemeProvider } from '@mui/material';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import SearchWidget from './containers/SearchWidget/Index';
import { SettingsContextProvider, useSettings } from './contexts/SettingsContext';
import "./contexts/i18n";
import axios from 'axios';
import CreateTheme from "./theme";
import "./Widget.css";

const rootElement = window.document.getElementById('RTBookingWidget');
const root = ReactDOM.createRoot(rootElement);

const apiClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: RTBookingEngineSettings.RTBookingEngineWeb_URL,
  paramsSerializer: { indexes: true }
});

const AppWidget = () => {
  const { hotelSettings } = useSettings();

  return (
    <ThemeProvider theme={CreateTheme(hotelSettings.ColorSettings || {})}>
      <ScopedCssBaseline>
        <BrowserRouter>
          <SearchWidget hotelId={rootElement.dataset.h_id} groupId={rootElement.dataset.g_id} />
        </BrowserRouter>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <SettingsContextProvider client={apiClient}>
      <AppWidget />
    </SettingsContextProvider>
  </React.StrictMode>
);