import { useCallback } from "react"

export const useAssetUrlCreator = () => {

  const createAssetUrl = useCallback((path) => {
    // eslint-disable-next-line no-undef
    return (process.env.NODE_ENV === 'development' || typeof RTBookingEngineSettings === 'undefined' ? '' : RTBookingEngineSettings.RTBookingEngineApp_URL) + path;
  }, []);

  return {
    createAssetUrl,
  };
}