import { Grid, Typography, styled } from "@mui/material";
import Switch from "./Switch";

const SwitchWithLabel = ({
  checked,
  handleChange,
  labelTextT = '',
  labelTextF = '',
  labelColor = '',
}) => {

  const Label = styled(Typography)(()=>({
    fontSize: '14px',
    color: labelColor,
  }));

  return (
    <Grid
      container
      columnGap='4px'
    >
      <Label
        variant="body1"
        sx={{
          opacity: checked ? 0.6 : 1,
        }}
      >
        { labelTextF }
      </Label>
      <Switch checked={checked} handleChange={handleChange} />
      <Label
        variant="body1"
        sx={{
          opacity: checked ? 1 : 0.6,
        }}
      >
        { labelTextT }
      </Label>
    </Grid>
  );
}

export default SwitchWithLabel;