import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? './RTBookingEngineWeb/' : '/',
  paramsSerializer: { indexes: true }
});

// api共通処理
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error.response){
      const errorCode = error.response.data?.commonInformation?.errorInfos?.errorCode;
      return Promise.reject({statusCode: error.response.status,
                            errorCode: errorCode,
                            errorCodeDisp: errorCode ? `(${errorCode})` : '',
                            data: error.response.data});
    }else{
      return Promise.reject({statusCode: '500',
                            errorCode: '',
                            errorCodeDisp: '',
                            data: null});
    }
  }
);

const IsNotFoundStatus = (status) =>{
  return status === 404;
}

export {apiClient, IsNotFoundStatus};