import { Button, Typography } from "@mui/material";
import ArrowDownIcon from '../assets/images/icons/ep_caret-bottom.svg';
import ArrowUpIcon from '../assets/images/icons/arrow_up.svg';
import { useAssetUrlCreator } from "../helpers/assetUrlCreator";

const ButtonCollapseTrigger = ({
  text = '',
  handleClick = () => { },
  isPlaceholderText = false,
  isTextCenter = false,
  textSize = 1,
  isCollapseOpen = false,
  disabled = false,
  withoutBorder = false,
}) => {
  const { createAssetUrl } = useAssetUrlCreator();

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      variant={withoutBorder ? 'text' : "outlined"}
      fullWidth={!withoutBorder}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      disableElevation
      endIcon={<img src={createAssetUrl(isCollapseOpen ? ArrowUpIcon : ArrowDownIcon)} alt="icon" />}
      sx={{
        height: withoutBorder ? 'auto' : '40px',
        padding: textSize === 3 ? '10px 12px 12px' : '10px 12px',
        borderRadius: '2px',
        border: (theme) => withoutBorder ? '' : `1px solid ${theme.palette.primary.main}`,
        justifyContent: isTextCenter ? 'center' : 'left',
        boxShadow: 0,
        bgcolor: '#fff',
        '&:hover': {
          bgcolor: '#fff',
        },
        '& .MuiButton-endIcon': {
          position: 'absolute',
          right: withoutBorder ? '4px' : '15px',
        },
        '&:focus': {
          border: (theme) => withoutBorder ? '' : `3px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <Typography
        variant={textSize === 2 ? 'body1' : 'body2'}
        fontSize={{ xs: textSize === 3 ? '12px' : '14px', sm: textSize === 2 ? '16px' : textSize === 3 ? '12px' : '14px', }}
        fontWeight={textSize === 2 ? 500 : 400}
        sx={{
          maxWidth: textSize === 2 ? '280px' : '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          marginRight: withoutBorder ? '12px' : '15px',
          color: (theme) => isPlaceholderText ? theme.palette.secondary.gray : theme.palette.primary.main,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}

export default ButtonCollapseTrigger;