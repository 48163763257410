import { MenuItem, Select as MuiSelect } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectIcon from '../assets/images/icons/ep_caret-bottom.svg';
import SelectIconUp from '..//assets/images/icons/arrow_up.svg';
import { useAssetUrlCreator } from "../helpers/assetUrlCreator";

const Select = ({
  items,
  value,
  handleChange,
  unselectedText,
  showPlaceholder = false,
  error = false,
  startAdornment = null,
  renderItem = (item) => item.text,
  sx = {},
  onBlur = () => {},
}) => {
  const { t } = useTranslation();
  const { createAssetUrl } = useAssetUrlCreator();

  // アイコンクリックでプルダウンの開閉を制御するため、開閉状態を管理します。
  const [ isOpen, setIsOpen ] = useState(false);

  const placeholderText = unselectedText || t('placeholder.please_choose');

  return (
    <MuiSelect
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onBlur={onBlur}
      displayEmpty
      variant="outlined"
      fullWidth
      error={error}
      sx={{
        ...{
          borderRadius: '2px',
          paddingLeft : '0px',
          height: '40px',
          fontFamily: 'Noto Sans Regular',
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: '14px',
          position: 'relative',
          bgcolor: (theme) => theme.palette.secondary.white,
          color: (theme) => items.some(item => item.value === value) || !showPlaceholder ? theme.palette.primary.main : theme.palette.secondary.gray,
          '& fieldset':{
            borderColor: (theme) => theme.palette.primary.main,
          },
          '& .MuiSelect-outlined': {
            padding: '10px 12px',
          },
          '&:hover': {
            border: 'unset',
            outline: 'unset',
          },
          '&:focus-visible': {
            border: 'unset',
            outline: 'none',
          },
          '&:focus': {
            border: 'unset',
            outline: 'none',
          },
        },
        ...sx,
      }}
      IconComponent={() => (
        <img
          src={createAssetUrl(isOpen ? SelectIconUp : SelectIcon)}
          alt="custom-icon"
          style={{ 
            cursor: 'pointer',
            position: 'absolute',
            pointerEvents:'none',
            right: '10px'
          }}
        />
      )}
      startAdornment={
        startAdornment &&
        <div 
          onClick={() => setIsOpen(!isOpen)}
          style={{ 
            padding : '20px 0px 20px 14px' ,
            cursor: 'pointer',
          }}
        >
          {startAdornment}
        </div>
      }
      MenuProps={{
        autoFocus: false,
        PaperProps: {
          sx: {
            bgcolor: (theme) => theme.palette.background.second,
            color: (theme) => theme.palette.primary.main,
            "& .MuiList-root": {
              padding: '0',
              bgcolor: (theme) => theme.palette.background.second,
            },
            "& .MuiMenuItem-root": {
              fontSize: '14px',
              fontWeight: 400,
              fontStyle: 'normal',
              fontFamily: "Noto Sans Regular",
              color: (theme) => theme.palette.primary.main,
              padding: '6px 30px',
              position: 'relative',
              whiteSpace: 'normal',
              wordBreak: 'break-all',
              "& img": {
                position: 'absolute',
                left: '10px',
              }
            },
          },
        },
      }}
      renderValue={() => (
        <div>
          {
            items.some(item => item.value === value) ?
              items.find(item => item.value === value).text :
              placeholderText
          }
        </div>
      )}
    >
      {
        items.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
          >
            {renderItem(item)}
          </MenuItem>
        ))
      }
    </MuiSelect>
  );
}

export default Select;