import { Box } from "@mui/material";
import { Calendar as ReactCalendar } from "react-date-range";
import { ja } from "date-fns/locale";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Calendar = ({
  value = new Date(),
  handleChange = (value) => { },
  maxDate,
  minDate = new Date(),
  isFloat = false,
}) => {

  return (
    <Box
      sx={{
        width: '100%',
        position: isFloat ? 'absolute' : 'unset',
        zIndex: 2,
        '& .rdrMonth': {
          width: '100%',
          boxSizing: 'border-box',
        },
        '& .rdrCalendarWrapper': {
          border: '1px solid #CCC',
          borderRadius: '2px',
          width: '100%',
        },
        '& .rdrMonthAndYearWrapper': {
          paddingTop: 0,
          height: 'unset',
        },
        '& .rdrDateDisplayWrapper': {
          display: 'none',
        },
        '& .rdrDays': {
          fontSize: '14px',
        }
        }}
    >
      <ReactCalendar
        date={value}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        locale={ja}
      />
    </Box>
  );
}

export default Calendar;