import { Popover, Box } from "@mui/material";
import ButtonCollapseTrigger from "./ButtonCollapseTrigger";
import { useState } from "react";

const AccordionInputFloatPanel = ({
  children,
  text = '',
  isPlaceholderText = false,
  disabled = false,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ panelWidth, setPanelWidth ] = useState(0);

  const getParentButtonTag = (node) => {
    if (node.tagName === 'BUTTON') {
      return node;
    } else if (node.parentElement) {
      return getParentButtonTag(node.parentElement);
    } else {
      return null;
    }
  };

  const onClick = (e) => {
    const anchor = getParentButtonTag(e.target);
    setIsOpen(!isOpen);
    setAnchorEl(anchor);
    setPanelWidth(anchor.offsetWidth);
  };

  const onClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonCollapseTrigger
        text={text}
        handleClick={onClick}
        isPlaceholderText={isPlaceholderText}
        isCollapseOpen={isOpen}
        disabled={disabled}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          style: {
            width: panelWidth + 'px',
          },
        }}
      >
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.primary.border}`,
            background: (theme) => theme.palette.background.second,
            padding: '16px',
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
}

export default AccordionInputFloatPanel;