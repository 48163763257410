import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useRoomAndPersonFormatter = () => {

  const { t } = useTranslation();

  const _format = useCallback((roomCount, adultCount, childCount) => {
    return `${roomCount}${t('room')}　|　${t('adult')}${adultCount}${t('person')}　${t('child')}${childCount}${t('person')} /${t('room')}`;
  }, []);
  
  const format = useCallback((roomAndPersonCounts) => {
    return _format(roomAndPersonCounts.roomCount, roomAndPersonCounts.adultCount, roomAndPersonCounts.upperElementaryCount + roomAndPersonCounts.lowerElementaryCount + roomAndPersonCounts.toddlerWithMealsAndBeddingCount + roomAndPersonCounts.toddlerWithMealsOnlyCount + roomAndPersonCounts.toddlerWithBeddingOnlyCount + roomAndPersonCounts.toddlerWithoutMealsAndBeddingCount);
  }, []);

  const formatBooking = useCallback((booking) => {
    return _format(booking.TotalRoomCount, booking.TotalPaxCount, booking.TotalChildPaxCount);
  }, []);

  return { format, formatBooking };
}
