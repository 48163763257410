const ja = {
  translation: {
    // 画面タイトル
    title_reservation_plan: 'プランを選択',
    title_reservation_room: 'お部屋を選択',
    title_vacancy_calendar: 'お部屋を選択',
    title_customer_info: 'お客様情報入力',
    confirm_detail_book_label: '予約内容の確認',
    confirm_cancel_room: '予約内容の確認・キャンセル',
    cancel_book_room: '予約のキャンセル',
    // ステッパー
    customer_info: '❶ お客様情報の入力',
    confirm_detail_book_room: '② 予約内容の確認',
    complete_book_room: '③ 予約確定',
    confirm_detail_book_room_active: '❷ 予約内容の確認',
    complete_book_room_active: '❸ 予約確定',
    // ラベル 
    square_label: '■{{item}}',
    colon_label: '{{label}}：{{item}}',
    colon_label_margin: '{{label}}　：　{{item}}',
    checkin_between: ' {{from}} ～ {{to}}',
    checkout_to: '～ {{to}}',
    type_room: '部屋タイプ',
    number_room_person: '室数/人数',
    number_room_person_margin: '室数 / 人数',
    checkin: 'チェックイン',
    checkout: 'チェックアウト',
    number_nights: '泊数',
    number_room: '室数',
    adult: '大人',
    child: '子供',
    children: '子供(小学生)',
    child_infant: '子供(幼児)',
    meals_condition: '食事条件',
    smoking_no_smoking: '禁煙 / 喫煙',
    stay_schedule: 'ご滞在日程',
    facility_selection: '施設選択',
    plan_introduction: 'プランの紹介',
    room_introduction: '部屋の紹介',
    room_facilities: '部屋の設備',
    amenities: 'アメニティ',
    cancellation_policy: 'キャンセルポリシー',
    child_rate: 'お子様料金',
    required: '必須',
    name: '氏名',
    name_kana: '氏名(かな)',
    email: 'メールアドレス',
    email_confirm: 'メールアドレス(確認用)',
    phone_number: '電話番号',
    zipcode: '郵便番号',
    address: '住所',
    checkin_time: 'チェックイン時刻',
    other_request: 'その他ご要望など',
    sales_company: '販売会社',
    registration_number: '登録番号',
    card_info: 'カード情報入力',
    card_number: 'カード番号',
    expired_year: '年',
    expired_month: '月',
    expired_date: '有効期限',
    card_owner: 'カード名義',
    security_code: 'セキュリティコード',
    guide: 'ご案内',
    method_pay: 'お支払い方法選択',
    available_card_brands: '利用可能なカードブランド',
    available_payment_method: '利用可能な決済方法',
    policy_and_security: '約款・プライバシーポリシー',
    room_name: '部屋',
    planing: 'プラン',
    info_book_room: '予約情報',
    detail_fee: '料金明細',
    payment_method: '支払い方法',
    credit_info: 'クレジットカード情報',
    hotel_fee: '宿泊料金',
    info_customer: 'お客様情報',
    info_sales_company: '販売会社詳細',
    book_code: '予約番号',
    book_confirm_key: '確認キー',
    book_room_status: '予約状態',
    cancel_fee: 'キャンセル料',
    detail_cancel_fee: 'キャンセル料内訳',
    rate_cancel_room: 'キャンセル料率 (お1人様 1泊あたり)',
    rate_cancel: 'キャンセル料率',
    fee_cancel: 'キャンセル料計',
    subtotal: '小計{{spacer}}{{money}}円',
    total_amount: '合計金額',
    rate_special_note: '料金に関する特記事項',
    confirmation_items: '確認事項等',
    contract_type: '契約形態',
    travel_arrangements: '旅行の手配について',
    transaction_terms_statement_delivery: '取引条件説明書面の交付など',
    other_notes: 'その他の注意事項',
    // タブ
    list_room: '部屋一覧',
    list_plan: 'プラン一覧',
    schedule_recruitment: '空室カレンダー',
    // 並び順
    sort_order: {
      recommended: 'おすすめ順',
      low_price: '価格が安い順',
      high_price: '価格が高い順',
    },
    // ボタン
    show_all_plan: '全てのプランを表示',
    show_all_room: '全ての部屋を表示',
    search: '検索',
    detail_reserve: '詳細・予約',
    change_condition_search: '検索条件変更',
    book_room: '予約に進む',
    check_detail_book_room: '予約内容を確認する',
    view_bill_statement: '料金明細を表示',
    back_to_input_customer_info: 'お客様情報入力へ戻る',
    confirm_book: '予約を確定する',
    click_to_cancel: 'ご予約の確認・キャンセルはこちら',
    btn_cancel_book_room: '予約をキャンセルする',
    btn_back_to_confirm_book_room: '予約内容確認へ戻る',
    btn_back_to_detail_book_room: '予約詳細画面へ戻る',
    search_btn: '空室検索',
    back_to_search: '検索画面へ戻る',
    cancel: 'キャンセル',
    book_room_agree_policy_and_security: '約款・プライバシーポリシーに同意して予約に進む',
    back_to_login: '予約ログイン画面へ戻る',
    back_to_input: 'お客様入力画面へ戻る',  
    // リンク
    cancel_room_link: '▶︎ ご予約確認・キャンセルはこちら',
    // 空室情報
    circle: '○',
    triangle: '△',
    cross: '×',
    hyphen: '-',
    empty_room: '{{mark}} 空室',
    little_room: '{{mark}} 残りわずか',
    out_of_room: '{{mark}} 満室',
    not_for_sale: '{{mark}} 販売なし',
    // 室タイプ
    room_type: {
      single_room: 'シングルルーム',
      semi_x2_room: 'セミダブルルーム',
      x2_room: 'ダブルルーム',
      twin_room: 'ツインルーム',
      x3_room: 'トリプルルーム',
      four_bedroom: '4ベッドルーム',
      room_japan: '和室',
      room_west: '和洋室',
      other_room_type: 'その他', 
    },
    // 食事条件
    meal_condition: {
      day_trip_breakfast: '日帰り(朝食付)',
      day_trip_lunch: '日帰り(昼食付)',
      day_trip_dinner: '日帰り(夕食付)',
      day_trip_no_meals: '日帰り(食事無)',
      with_dinner: '夕食付',
      with_breakfast: '朝食付',
      full_board: '3食付',
      half_board: '2食付',
      with_lunch: '昼食付',
      late_night_meal: '夜食付',
      a_meal_only: '1食のみ',
      no_meal: '食事なし',
      other_meals: 'その他',
      with_dinner_breakfast: '夕朝',
      with_breakfast_lunch: '朝昼',
      with_lunch_dinner: '昼夕',
    },
    // 部屋の特徴
    room_feature: {
      no_smoking: '禁煙',
      smoking: '喫煙',
    },
    // 支払方法
    payment: {
      local_cash: '現地払い(現金)',
      local_card: '現地払い(クレジットカード)',
      advance_card: '事前カード決済',
    },
    cash: '現地払い',
    payment_online: 'オンラインカード決済',
    // お金
    tax: '税込',
    yen: '円',
    total: '合計',
    tax_parenthesis: '(税込)',
    yen_discount:'円引き',
    total_expense: '宿泊料金合計',
    percent: '%',
    yen_mark: '¥',
    calculation_expense_room: '{{rate}}円 × {{count}}室 = {{total}}円',
    formula_expense_personal: '{{rate}}円 × {{count}}名',
    formula_expense_room: '{{rate}}円 × {{count}}室',
    // キャンセル
    cancel_fee_nothing: 'キャンセル料なし',
    hotel_fee_percent: '宿泊料金の{{percent}}%',
    formula_cancel_fee: '{{rate}}円 × {{percent}}％',
    appointed_day: '当日',
    room_date_between: '宿泊日の{{from}}日前から{{to}}日前まで',
    no_show: 'No Show',
    // お子様料金補足
    child_rate_guide: {
      title: 'お子様料金について',
      description: 'お子様料金は、小学生以下のお子様が対象です。（中学生以上は、大人料金になります）',
      content_title: '＜ 料金の見方 ＞',
      percentage: '○○%',
      percentage_description: '大人料金の○○%。',
      discount: '○○円引き',
      discount_description: '大人料金から○○円引き。',
      fixed_price: '○○円',
      fixed_price_description: '大人料金に関係なく、○○円。',
      free_price: '0円',
      free_price_description: '無料です。',
    },
    // その他
    room: '室',
    person: '名',
    see_more: 'もっと見る',
    close: '閉じる',
    room_label: 'お部屋',
    rooms:'{{count}}室目',
    dayuse: '日帰り',
    stay: '宿泊',
    nights: '{{day}}泊',
    room_capacity: '{{minMember}}名〜{{maxMember}}名',
    items:'{{count}}件',
    items_parenthesis: '（{{count}}件）',
    postal_mark: '〒',
    // プレースホルダ―
    placeholder: { 
      name: '宿泊 太郎',
      name_kana: 'しゅくはく たろう',
      email: 's.taro@example.com',
      email_confirm: 's.taro@example.com',
      phone_number: '08012345678',
      zipcode: '1234567',
      receiver_room_time: '15:00',
      card_number: '1234567890123456',
      security_code: '123',
      card_owner: 'TARO SHUKUHAKU',
      please_choose: '選択してください',
      book_code: 'CI19000101123456',
      confirm_key: 'e7c29773435c'
    },
    // メッセージ
    message: { 
      title_modal_card: 'クレジットカードのセキュリティコードとは？',
      guide_use_card: 'カードの不正利用を防ぐために使用する3桁または4桁の番号です。',
      guide_security_code: 'カード裏面の署名欄に記載されている数字の下3桁',
      guide_security_code2: 'カード表面の右端、または左端に記載されている4桁の数字',
      title_complete_book_room: '予約が確定しました。',
      registration_info: '＜ 予約番号： {{rakutsuBookingNo}}<l />確認キー： {{confirmationKey}} ＞',
      note_book_room: '予約の確認・キャンセルには、上記の予約番号、確認キーが必要になります。<br />必ず、本画面を保存いただくか、メモをお控えください。',
      check_book_registration_info: '予約番号と確認キーで、予約内容の確認・キャンセルができます。',
      waring_cancel: '一度キャンセルしたご予約は元に戻すことはできません。キャンセルするご予約にお間違いがないか、今一度ご確認ください。',
      waring_cancel_payment: 'キャンセル料のお支払い方法につきましては、当館までお問い合わせください。',
      cancel_complete: '予約がキャンセルされました',
      notification_send_mail: 'ご入力いただいたメールアドレス宛に確認メールを送信いたします。',
      notification_send_mail_cancel: 'ご入力いただいたメールアドレス宛にキャンセル確認メールを送信いたします。',
      contact_if_not_receiver_mail: 'メールが届かない場合、当館までお問い合わせください。',
      see_you_again: 'またのご利用をお待ちしております。',
      name_input_caution: '※日本語以外の氏名の方は、半角アルファベットでご入力ください。',
      allow_receive_emails_facility_domain:'※メールの受信拒否設定をされている場合はドメイン「{{domain}}」からのメール受信を許可してください。',
      zipcode_input_caution: '※日本以外にお住まいの方は郵便番号に「0000000」とご入力ください。',
      address_input_caution:'※住所は番地、建物名、部屋番号までご入力ください。',
      guide_note: '契約成立日がクレジットカード決済日となります。 ご利用可能なクレジットカードは、日本国内で使用可能なものになります（一部の提携カードでご利用いただけない場合がございます)。 クレジットカードご利用明細等におけるお支払先の表示は、「タイムデザイン（旅行予約）」となりますので、ご了承ください。',
      please_close_browser: 'ブラウザの×ボタンを押して、このページを閉じてください。',
      cancel_charge_difference: '表示されているキャンセル料に変更がありました。<br />内容をご確認の上、再度予約キャンセルをお願いします。',
      contact_for_receipt: '※領収書が必要な場合は当館までお問い合わせください。',
      please_not_click_more_than_twice: '※「予約を確定する」ボタンを2回以上クリックしないでください。',
      // エラーメッセージ
      error: {
        please_search_again: 'お手数ですが、再度検索してください。',
        failed_book_room: '予約に失敗しました。',
        invalid_credit_card: 'クレジットカード情報に誤りがあります。{{code}}',
        exceeding_credit_limit: 'クレジットカード利用限度額を超過しています。{{code}}',
        authentication_failure: '認証に失敗しました。{{code}}',
        not_exist_book_room: 'ご指定の予約は存在しません。',
        failed_get_info_book_room: '予約情報の取得に失敗しました。{{code}}',
        not_found_plan: '該当するプランが見つかりませんでした。',
        not_found_info_book_room : '該当する予約情報はありませんでした。',
        failed_cancel_book_room: '予約のキャンセルに失敗しました。{{code}}',
        please_try_again: 'しばらく時間をおいてから、再度お試しください。',
        please_wait_and_try_again: 'しばらく時間をおいてから、再度お試しください。',
        please_contact_admin: 'お手数ですが、サイト管理者にお知らせください。',
        failed_payment: '決済に失敗しました。{{code}}',
        not_found_selected_condition_search_again: '選択された条件でご予約いただける{{tab}}が見つかりませんでした。<br />条件を変更して、再度検索してください。',
        failed_search_please_search_again_later: '検索に失敗しました。<br />しばらく時間をおいてから、再度検索してください。{{code}}',
        failed_get_facility_info: '施設情報の取得に失敗しました。',
        unexpected_exception: '予期しないエラーが発生しました。',
        failed_display_page: 'ご指定のページを表示することができませんでした。',
        error_has_occured: 'エラーが発生しました。',
        search_widget_error: '空室検索ウィジェットの表示に失敗しました。',
        please_start_over: 'お手数ですが、はじめからやり直してください。'
      },
      // 検証用メッセージ
      validation: { 
        required: '{{label}}を入力してください。',
        max_length: '{{max}}文字以下を入力してください。',
        invalid_mail_format: 'メールアドレスの形式が正しくありません。',
        half_width_numbers: '半角数字を入力してください。',
        half_width_alphanumeric: '半角英数を入力してください。',
        unmatched_input_mail:'入力されたメールアドレスが一致しません。',
        max_min_length: '{{min}}～{{max}}文字を入力してください。',
        required_selected: '{{label}}を選択してください。',
        invalid_input: '{{label}}に誤りがあります。',
        expired: '有効期限が切れたカードは使用できません。',
        select_facility: '施設を選択してください。',
      }
    },
    // 予約状態識別子
    data_classification: { 
      pre_book: '仮予約',
      new_book: '予約済',
      cancellation: 'キャンセル済'
    },
    // 人数
    member: {
      adult: '大人',
      child_elementary: '小学生(高学年)',
      child_student: '小学生(低学年)',
      infant_meals_futon: '幼児(食事・布団あり)',
      infant_meals_provided: '幼児(食事のみ)',
      infant_futon_provided: '幼児(布団のみ)' ,
      infant_no_meals_no_futon: '幼児(食事・布団なし)',
    },
    // 人数条件
    member_condition: {
      elementary: '高学年',
      student: '低学年',
      meals_futon: '食事あり<br />布団あり',
      meal_only: '食事のみ',
      futon_only: '布団のみ',
      no_meals_no_futon: '食事なし<br />布団なし',
    },
    // カードブランド
    card_brand: { 
      visa: 'VISA',
      jcb: 'JCB',
      master_card: 'Master Card',
      american_express: 'American Express',
      diners_club: 'Diners Club',
    },
    // 部屋設備
    room_facility: { 
      internet: 'インターネット',
      bus: 'バス',
      shower: 'シャワー',
      toilet: 'トイレ',
      unit_bus: 'ユニットバス',
      safe: '金庫',
      refrigerator: '冷蔵庫',
      tv_free: 'テレビ（無料）',
      tv_paid: 'テレビ（有料）',
      air_conditioner: 'エアコン',
      air_cleaner: '空気清浄機',
      humidifier: '加湿器',
      pot: 'ポット',
      tea: 'お茶',
      hairdryer: 'ドライヤー',
      hairdryer_rental: 'ドライヤー（貸出）',
      iron: 'アイロン',
      trouser_presser: 'ズボンプレッサー',
    },
    // アメニティ
    room_amenity: { 
      shampoo_conditioner: 'シャンプー・コンディショナー',
      body_wash_soap: 'ボディーソープ・石鹸',
      face_wash_soap: '洗顔ソープ',
      bath_towel: 'バスタオル',
      hand_towel: 'ハンドタオル',
      toothbrush: '歯ブラシ',
      comb_brush: 'くし・ブラシ',
      razor: 'カミソリ',
      shower_cap: 'シャワーキャップ',
      pajamas: 'パジャマ',
      bathrobe: 'バスローブ',
      yukata: '浴衣',
      slipper: 'スリッパ',
    },
    // ホテペイ
    hotel_pay: {
      company_name: '株式会社タイムデザイン',
      registration_number: '(社)日本旅行業協会正会員 観光庁長官登録旅行業 第1977号',
      address: '〒150-0013 東京都渋谷区恵比寿1-18-14 恵比寿ファーストスクエア8F',
      contract_type: '手配旅行',
      message: {
        plan_notes: '※事前カード決済でのお支払いの場合は、株式会社タイムデザインとの手配型旅行契約となります。<br />※旅行業務取扱：観光庁長官登録旅行業第1977号JATA正会員　株式会社タイムデザイン　東京都渋谷区恵比寿1-18-14-8F　<l>旅行業約款・条件書等</l>',
        guide_note: '※クレジットカードでのオンライン決済は株式会社タイムデザインとの手配旅行契約、クレジットカードによる事前決済となります。旅行手配等のために必要な範囲で、お客様の個人情報をホテペイの運営会社である株式会社タイムデザインに提供いたします。',
        contract_completed: 'このページの表示をもって株式会社タイムデザインとの手配旅行の契約が成立しました。',
        saved_transaction_terms_statement: '取引条件説明書面を電磁的方法で交付することを承諾します。また、取引条件説明書面として、このページと、<l>取引条件説明書面（共通事項）</l>に表示された内容を保存しました。',
        book_room_agree_handling_of_personal_information: '右記の個人情報の取り扱いについて同意します。<l>個人情報の取り扱いについて</l>',
        travel_arrangements: '本ご旅行は、<l1>旅行業約款（手配旅行契約の部）</l1>に基づきお引き受けする旅行であり、お客様と株式会社タイムデザインが締結する手配旅行契約となります。お申込み前に必ず<l2>取引条件説明書面（共通事項）</l2>をご覧ください。',
        transaction_terms_statement_delivery: 'このページと、このページの「<l>取引条件説明書面（共通事項）</l>」のリンクをクリックして表示される「旅行条件書」ページ（HTMLファイル）の両方をお客様のPCに保存するか、 印刷して保存するかしてください。これにより、当社は取引条件を説明し、同書面を交付したものとして取り扱わさせていただきます。<br />※契約成立通知画面（予約完了画面）が表示された時点をもって、旅行契約が成立するものとし、上記の記載内容をもって契約書面の内容とさせていただきます。',
        other_notes: 'a.健康を害している方、b.身体に障がいのある方、c.妊娠中の方、d.補助犬使用の方、その他特別な配慮を必要とする方は、その旨を宿泊機関に直接お申し出ください。<br />株式会社タイムデザインは、お客様のカードよりお客様の署名なくして旅行代金の支払いを受けます。<br />契約成立通知画面（予約完了画面）が表示された時点をもって、旅行契約が成立するものとします。<br />株式会社タイムデザインが善良な管理者の注意をもって旅行サービスを手配したときは、手配旅行契約に基づく株式会社タイムデザインの債務の履行は終了します。',
      },
      url: {
        // 旅行業約款・条件書等
        travel_industry_terms_and_conditions_etc: 'https://hotelpaysupport.zendesk.com/hc/ja/sections/21835045455769',
        // 取引条件説明書面
        transaction_terms_statement: 'https://hotelpaysupport.zendesk.com/hc/ja/articles/21841344720793',
        // 個人情報の取り扱いについて
        handling_of_personal_information: 'https://hotelpaysupport.zendesk.com/hc/ja/sections/21837044900249',
      },
    },
  },
};

export default ja;
