import { Box, Button, Grid, Popover, Typography, styled } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next";
import Select from "../../components/Select";
import { useEffect, useRef, useState } from "react";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import ButtonCollapseTrigger from "../../components/ButtonCollapseTrigger";
import Calendar from "../../components/Calendar";
import RoomAndPersonCounter from "../../components/RoomAndPersonCounter";
import { Link } from "react-router-dom";
import { formatDate } from '../../helpers/dateFormatter';
import { useSettings } from "../../contexts/SettingsContext";
import { addDays } from "date-fns";
import { useSearchCondition } from "../../helpers/searchCondition";
import { useRoomAndPersonFormatter } from "../../helpers/roomAndPersonFormatter";
import AccordionInputFloatPanel from "../../components/AccordionInputFloatPanel";

const Index = ({
  hotelId = null,
  groupId = null,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { hotelSettings, groupSettings, valueSettings, fetchSettings, hasError, isLoading: isSettingLoading } = useSettings();
  
  const {
    searchCondition,
    setIsDayuse,
    setCheckinDate,
    setStayDays,
    setRoomAndPersonCounts,
    stayDayItems,
    createSearchConditionUrlParameter,
  } = useSearchCondition(true);
  const { format: formatRoomAndPerson } = useRoomAndPersonFormatter();

  useEffect(() => {
    fetchSettings(hotelId, groupId);
  }, []);

  const SearchConditionLabel = styled(Typography)(({theme}) => ({
    fontSize: '14px',
    color: theme.palette.widget.contrastText,
  }));
  const LinkedLabel = styled(Link)(({theme}) => ({
    fontSize: '14px',
    color: theme.palette.widget.contrastText,
    cursor: 'pointer',
  }));
  const ErrorMessageLabel = styled(Typography)(({theme}) => ({
    fontSize: '14px',
    color: theme.palette.widget.contrastText,
  }));

  const facilities = groupId === null || !groupSettings.HotelSettings.HotelSelect ?
    [] :
    groupSettings.HotelSettings.HotelList.map(f => ({
      value: f.HotelId,
      text: f.HotelName,
    }));
  const [ facility, setFacility ] = useState('');
  const handleFacilityChange = (v) => {
    setFacilityErrorMessage('');
    setFacility(v);
  }
  const [ facilityErrorMessage, setFacilityErrorMessage ] = useState('');

  const handleDayUseChange = (v) => setIsDayuse(v);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ calendarWidth, setCalendarWidth ] = useState(0);
  const [ isCheckinCalendarOpen, setIsCheckinCalendarOpen ] = useState(false);
  const handleCheckinDateChange = (v) => {
    setCheckinDate(v);
    setIsCheckinCalendarOpen(false);
    setAnchorEl(null);
  }

  const validate = () => {
    if (0 < facilities.length && facility === '') {
      setFacilityErrorMessage(t('message.validation.select_facility'));
      return true;
    }
  }

  const createPageUrl = (path, searchParams = '') => {
    // eslint-disable-next-line no-undef
    return (process.env.NODE_ENV === 'development' || typeof RTBookingEngineSettings === 'undefined' ? './' : RTBookingEngineSettings.RTBookingEngineApp_URL) + path + (searchParams ? '?' + searchParams : '');
  }

  const handleSearch = () => {
    if (validate()) {
      return;
    }

    const searchParams = createSearchConditionUrlParameter();
    searchParams.set('h_id', 0 < facilities.length ? facility : hotelId);
    window.open(createPageUrl('result-search', searchParams), '_blank');
  }

  const handleConfirmationLinkClick = (e) => {
    if (validate()) {
      e.preventDefault();
      return;
    }
  }

  const getParentButtonTag = (node) => {
    if (node.tagName === 'BUTTON') {
      return node;
    } else if (node.parentElement) {
      return getParentButtonTag(node.parentElement);
    } else {
      return null;
    }
  }

  if (hasError) {
    return <Typography variant="body1" fontSize='48px' textAlign='center' width='100%'>{t('message.error.search_widget_error')}</Typography>
  }

  return (
    <>
    <Grid
      container
      direction='column'
      alignItems='stretch'
      gap={3.5}
      sx={{
        padding: { xs: '40px 10px', se: '20px' },
        border: `1px solid ${theme.palette.primary.border}`,
        borderRadius: '2px',
        bgcolor: 'widget.main',
        boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.25)',
        marginTop: '10px',
        marginBottom: '20px',
        visibility: isSettingLoading ? 'hidden' : 'visible',
      }}
    >
      <Grid item container gap={5} justifyContent='space-around'>
        { /* 施設選択 */
          0 < facilities.length &&
          <Grid item xs={12} sm container direction='column' rowGap={1.5} minWidth='270px' paddingBottom={1.5}>
            <Grid item>
              <SearchConditionLabel variant='body1'>{t('facility_selection')}</SearchConditionLabel>
            </Grid>
            <Grid item sx={{ width: "100%", }}>
              <Select
                items={facilities}
                value={facility}
                handleChange={handleFacilityChange}
                showPlaceholder={!facility}
                sx={{ 
                  height: 'unset',
                  '& .MuiSelect-select div': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }
                 }}
              />
              <ErrorMessageLabel variant="body2">{facilityErrorMessage}</ErrorMessageLabel>
            </Grid>
          </Grid>
        }
        {/* チェックイン */}
        <Grid item xs={12} sm container direction='column' rowGap={1.5} minWidth='270px'>
          <Grid item container>
            <SearchConditionLabel variant='body1'>{t('checkin')}</SearchConditionLabel>
            {
              hotelSettings.DayuseSettings.Dayuse &&
              <Grid item xs container justifyContent='flex-end'>
                <Box>
                  <SwitchWithLabel
                    checked={searchCondition.isDayuse}
                    handleChange={handleDayUseChange}
                    labelTextT={t('dayuse')}
                    labelTextF={t('stay')}
                    labelColor={theme.palette.widget.contrastText}
                  />
                </Box>
              </Grid>
            }
          </Grid>
          <Grid item container justifyContent='space-between' columnSpacing={2.5}>
            <Grid item xs={ searchCondition.isDayuse ? 12 : 8}>
              <ButtonCollapseTrigger
                text={formatDate(searchCondition.checkinDate, 'M月d日(E)')}
                handleClick={(e) => {
                  const anchor = getParentButtonTag(e.target);
                  setAnchorEl(anchor);
                  // 泊数プルダウンも含めた横幅を取得するため、先祖要素の幅を取得する
                  setCalendarWidth(anchor.parentElement.parentElement.offsetWidth - 10);
                  setIsCheckinCalendarOpen(!isCheckinCalendarOpen);
                }}
                isCollapseOpen={isCheckinCalendarOpen}
              />
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={isCheckinCalendarOpen}
                onClose={() => {
                  setIsCheckinCalendarOpen(false);
                  setAnchorEl(null);
                }}
                PaperProps={{
                  style: {
                    width: calendarWidth + 'px',
                  }
                }}
              >
                <Calendar
                  value={searchCondition.checkinDate}
                  handleChange={handleCheckinDateChange}
                  maxDate={addDays(new Date(), valueSettings.MaxCheckinDate || 366)}
                  minDate={new Date()}
                />
              </Popover>
            </Grid>
            {
              !searchCondition.isDayuse &&
              <Grid item xs={4}>
                <Select items={stayDayItems} value={searchCondition.stayDays} handleChange={(v) => setStayDays(v)} />
              </Grid>
            }
          </Grid>
        </Grid>
        {/* 室数/人数 */}
        <Grid item xs={12} sm container direction='column' rowGap={1.5} position='relative' minWidth='270px'>
          <Grid item>
            <SearchConditionLabel variant='body1'>{t('number_room_person')}</SearchConditionLabel>
          </Grid>
          <Grid item zIndex={1}>
            <AccordionInputFloatPanel text={formatRoomAndPerson(searchCondition.roomAndPersonCounts)}>
              <RoomAndPersonCounter
                value={searchCondition.roomAndPersonCounts}
                handleChange={setRoomAndPersonCounts}
                maxRoomCount={valueSettings.MaxRooms}
                maxPersonCount={valueSettings.MaxPax}
              />
            </AccordionInputFloatPanel>
          </Grid>
        </Grid>
        {/* 空室検索ボタン */}
        <Grid item xs={12} sm='auto' minWidth='270px' container direction='column' rowGap={1.5}>
          <Grid item>
            <SearchConditionLabel variant='body1'>&nbsp;</SearchConditionLabel>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button
              onClick={handleSearch}
              variant="contained"
              fullWidth
              color="buttonWidget"
              size="large"
              sx={{
                height: '40px',
                fontSize: '18px',
                maxWidth: { xs: '200px', sm: '100%' },
              }}
            >
              {t('search_btn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* 予約確認・キャンセル */}
      <Grid item textAlign={{xs: 'center', sm: 'left'}}>
        <LinkedLabel to={createPageUrl('login', 'h_id=' + (0 < facilities.length ? facility : hotelId))} target="_blank" onClick={handleConfirmationLinkClick}>{t('cancel_room_link')}</LinkedLabel>
      </Grid>
    </Grid>
    </>
  );
}

export default Index;