import { useState } from "react";

export const useRoomAndPerson = ({
  roomCount = 1,
  adultCount = 1,
  upperElementaryCount = 0,
  lowerElementaryCount = 0,
  toddlerWithMealsAndBeddingCount = 0,
  toddlerWithMealsOnlyCount = 0,
  toddlerWithBeddingOnlyCount = 0,
  toddlerWithoutMealsAndBeddingCount = 0,
}) => {

  const [ roomAndPersonCounts, setRoomAndPersonCounts ] = useState({
    roomCount,
    adultCount,
    upperElementaryCount,
    lowerElementaryCount,
    toddlerWithMealsAndBeddingCount,
    toddlerWithMealsOnlyCount,
    toddlerWithBeddingOnlyCount,
    toddlerWithoutMealsAndBeddingCount,
  });

  return { roomAndPersonCounts, setRoomAndPersonCounts };
}
